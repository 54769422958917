import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Hidden,
  IconButton,
  Link,
  Toolbar
} from '@material-ui/core';
import MenuIcon from '../icons/Menu';
import Logo from '../images/gleLogo.png';

const MainNavbar = (props) => {
  const { onSidebarMobileOpen } = props;

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.Primary'
      }}
    >
      <Toolbar sx={{ minHeight: 64, display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ maxWidth: 1400, width: '100%', display: 'flex', justifyContent: 'space-between', my: 2 }}>

            <Box sx={{ mx: 2 }} />
            <RouterLink to="/">
              <img
                src={Logo}
                alt="GoodLocal Express Logo"
                style={{ width: '125px' }}
              />
            </RouterLink>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Hidden mdDown>
              <Link
                color="textPrimary"
                href="/what-we-do"
                underline="none"
                variant="body1"
              >
                What We Do
              </Link>
              <Box
                sx={{
                  mx: 9
                }}
              />
              <Link
                color="textPrimary"
                href="/how-it-works"
                underline="none"
                variant="body1"
              >
                How It Works
              </Link>
              {/* <Box
                sx={{
                  mx: 7
                }}
              />
              <Link
                color="textPrimary"
                href="/shop"
                underline="none"
                variant="body1"
              >
                Shop
              </Link> */}
              <Box
                sx={{
                  mx: 9
                }}
              />
              <Link
                color="textPrimary"
                href="/faq"
                underline="none"
                variant="body1"
              >
                FAQ
              </Link>
              <Box
                sx={{
                  mx: 9
                }}
              />
              <Link
                color="textPrimary"
                href="/signup"
                underline="none"
                variant="body1"
              >
                Sign Up
              </Link>
              <Box
                sx={{
                  mx: 9
                }}
              />
              <Link
                color="textPrimary"
                href="/contact"
                underline="none"
                variant="body1"
              >
                Contact
              </Link>
              <Box
                sx={{
                  mx: 9
                }}
              />
              <Button
                color="primary"
                component="a"
                href="/dashboard"
                size="small"
                variant="contained"
              >
                Log In
              </Button>
              <Box sx={{ mx: 2 }} />
            </Hidden>
          </Box>
        </Box>
        <Hidden mdUp>
          <IconButton
            color="grey"
            onClick={onSidebarMobileOpen}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default MainNavbar;