import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import MinusIcon from '../icons/Minus';
import Logo from '../images/gleLogo.png';

const sections = [
  {
    title: 'Menu',
    links: [
      {
        title: 'What We Do',
        href: '/what-we-do'
      },
      {
        title: 'How It Works',
        href: '/how-it-works'
      },
      {
        title: 'Sign Up',
        href: '/signup'
      },
      {
        title: 'Shop',
        href: '/shop'
      }
    ]
  },
  {
    title: 'Help',
    links: [
      {
        title: 'FAQ',
        href: '/faq'
      },
      {
        title: 'Contact',
        href: '/contact'
      },
      {
        title: 'Privacy Policy',
        href: '/privacy'
      },
      {
        title: 'Terms and Conditions',
        href: '/terms'
      }
    ]
  },
  {
    title: 'Social',
    links: [
      {
        title: 'Instagram',
        href: 'https://www.instagram.com/goodlocal.ca/'
      },
      {
        title: 'Facebook',
        href: 'https://www.facebook.com/goodlocal.ca'
      },
      {
        title: 'LinkedIn',
        href: 'https://ca.linkedin.com/company/goodlocal-ca'
      }
    ]
  }
];
const Footer = (props) => (
  <Box
    sx={{
      backgroundColor: 'background.default',
      pb: 6,
      pt: {
        md: 15,
        xs: 6
      }
    }}
    {...props}
  >
    <Container maxWidth="lg">
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          md={3}
          sm={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            order: {
              md: 1,
              xs: 4
            }
          }}
          xs={12}
        >
          <img
            src={Logo}
            alt="GoodLocal Express Logo"
            style={{ width: '125px' }}
          />
          <Box sx={{ mt: 1 }}>
            <Typography
              color="textSecondary"
              variant="caption"
            >
              GoodLocal Express
              <br />
              223 McDermot Ave.
              <br />
              Winnipeg, MB
              <br />
              R3B 0S2
              <br />
              T: (204) 797-5796
              <br />
              E: vendors@goodlocal.ca
            </Typography>
          </Box>
        </Grid>
        {sections.map((section, index) => (
          <Grid
            item
            key={section.title}
            md={3}
            sm={4}
            sx={{
              order: {
                md: index + 2,
                xs: index + 1
              }
            }}
            xs={12}
          >
            <Typography
              color="textSecondary"
              variant="overline"
            >
              {section.title}
            </Typography>
            <List disablePadding>
              {section.links.map((link) => (
                <ListItem
                  disableGutters
                  key={link.title}
                  sx={{
                    pb: 0,
                    pt: 1
                  }}
                >
                  <ListItemAvatar
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      minWidth: 0,
                      mr: 0.5
                    }}
                  >
                    <MinusIcon color="primary" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={(
                      <Link
                        href={link.href}
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {link.title}
                      </Link>
                    )}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        ))}
      </Grid>
      <Divider
        sx={{
          borderColor: (theme) => alpha(theme.palette.primary.contrastText, 0.12),
          my: 6
        }}
      />
      <Typography
        color="textSecondary"
        variant="caption"
      >
        Copyright 2022 © GoodLocal
      </Typography>
    </Container>
  </Box>
);
export default Footer;
