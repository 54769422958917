import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Hidden, Typography } from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import DescriptionIcon from '@material-ui/icons/Description';
import Logo from '../../images/gleLogo.png';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import HomeIcon from '@material-ui/icons/Home';

const sections = [
  {
    title: 'Menu',
    items: [
      {
        title: 'Dashboard',
        path: '/dashboard',
        icon: <HomeIcon fontSize="small" />,
      },
      {
        title: 'Orders',
        path: '/dashboard/orders',
        icon: <DescriptionIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/orders',
            icon: <DescriptionIcon fontSize="small" />
          },
          {
            title: 'Create Order',
            path: '/dashboard/orders/create',
            icon: <DescriptionIcon fontSize="small" />
          },
          {
            title: 'Import Orders',
            path: '/dashboard/orders/import',
            icon: <DescriptionIcon fontSize="small" />
          }
        ]
      },
      {
        title: 'Invoices',
        path: '/dashboard/invoices',
        icon: <DescriptionIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/invoices/list',
            icon: <DescriptionIcon fontSize="small" />
          },
        ]
      },
      {
        title: 'Reports',
        path: '/dashboard/reports',
        icon: <DescriptionIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/report/list',
            icon: <DescriptionIcon fontSize="small" />
          },
        ]
      },
    ]
  }
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between'
        }}>
          <Box>
            <Hidden lgUp>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 2
                }}
              >
                <RouterLink to="/dashboard">
                  <img
                    src={Logo}
                    alt="GoodLocal Express Logo"
                    style={{ width: '125px' }}
                  />
                </RouterLink>
              </Box>
            </Hidden>
            <Box sx={{ p: 2 }}>
              <Box
                sx={{
                  alignItems: 'center',
                  backgroundColor: 'background.default',
                  borderRadius: 1,
                  display: 'flex',
                  overflow: 'hidden',
                  p: 2
                }}
              >
                <Avatar
                  src={user.avatar}
                  sx={{
                    cursor: 'pointer',
                    height: 48,
                    width: 48
                  }}
                />
                <Box sx={{ ml: 2 }}>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    {user.email}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              {sections.map((section) => (
                <NavSection
                  key={section.title}
                  pathname={location.pathname}
                  sx={{
                    '& + &': {
                      mt: 3
                    }
                  }}
                  {...section}
                />
              ))}
            </Box>
          </Box>
          <Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Need Help?
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                Contact <a href="mailto:vendors@goodlocal.ca">vendors@goodlocal.ca</a>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
