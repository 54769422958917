import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Drawer, Hidden, Link } from '@material-ui/core';
import Logo from '../images/gleLogo.png';

const MainSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  return (
    <Hidden lgUp>
      <Drawer
        anchor="right"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        PaperProps={{
          sx: {
            backgroundColor: 'background.default',
            width: 256
          }
        }}
      >
        <Box
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            height: '50%',
            p: 2
          }}
        >
          <Box
            sx={{
                my: 10
              }}
          />
          {/* <Link 
            href="/"
            onClick={onMobileClose}>
            <img
              src={Logo}
              alt="GoodLocal Express Logo"
              style={{ width: '125px' }}
            />
          </Link> */}
          <Link
            color="textSecondary"
            href="../"
            underline="none"
            variant="body1"
            onClick={onMobileClose}
          >
            Home
          </Link>
          <Box
            sx={{
                my: 2
              }}
          />
          <Link
            color="textSecondary"
            href="/what-we-do"
            underline="none"
            variant="body1"
            onClick={onMobileClose}
          >
            What We Do
          </Link>
          <Box
            sx={{
                my: 2
              }}
          />
          <Link
            color="textSecondary"
            href="/how-it-works"
            underline="none"
            variant="body1"
            onClick={onMobileClose}
          >
            How It Works
          </Link>
          {/* <Box
            sx={{
                my: 2
              }}
          />
          <Link
            color="textSecondary"
            href="/shop"
            underline="none"
            variant="body1"
          >
            Shop
          </Link> */}
          <Box
            sx={{
                my: 2
              }}
          />
          <Link
            color="textSecondary"
            href="/faq"
            underline="none"
            variant="body1"
          >
            FAQ
          </Link>
          <Box
            sx={{
                my: 2
              }}
          />
          <Link
            color="textSecondary"
            href="/signup"
            underline="none"
            variant="body1"
          >
            Sign Up
          </Link>
          <Box
            sx={{
                my: 2
              }}
          />
          <Link
            color="textSecondary"
            href="/contact"
            underline="none"
            variant="body1"
          >
            Contact
          </Link>
          <Box
            sx={{
                my: 2
              }}
          />
          <Button
            color="primary"
            component="a"
            href="/dashboard"
            size="small"
            variant="contained"
          >
            Log In
          </Button>
        </Box>
      </Drawer>
    </Hidden>
  );
};

MainSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default MainSidebar;
